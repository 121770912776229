import { Chip } from '@mui/material';
import { isEmpty, isNumber, map, transform } from 'lodash';
import React, { Fragment, useMemo } from 'react';

import { FilterDefinition } from 'types';

type FilterChip = {
  label: string;
  value: any;
  name: string;
  removable?: boolean;
};

type Props = {
  definitions: FilterDefinition[];
  filters: any;
  onDelete: (name: string) => void;
};

export const FiltersChips: React.FC<Props> = ({
  definitions,
  filters,
  onDelete,
}) => {
  const chips = useMemo(
    () =>
      transform(
        definitions,
        (acc, definition) => {
          const value = filters?.[definition.name] as never;
          if (!isEmpty(value) || isNumber(value)) {
            const displayName = definition.getDisplayName?.(value) || value;
            acc.push({
              label: `${definition.label}: ${displayName}`,
              value,
              name: definition.name,
              removable: definition.removable !== false,
            });
          }
        },
        [] as FilterChip[],
      ),
    [definitions, filters],
  );
  return (
    <Fragment>
      {map(chips, (chip) => (
        <Chip
          sx={{
            marginRight: 2,
            marginBottom: 2,
            // added support for multiline chip: https://mui.com/material-ui/react-chip/#multiline-chip
            height: 'auto',
            minHeight: '32px',
            padding: '4px 0',
            wordBreak: 'break-all',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
          key={chip.label}
          label={chip.label}
          variant="outlined"
          onDelete={chip.removable ? () => onDelete(chip.name) : undefined}
        />
      ))}
    </Fragment>
  );
};
